import { Badge, Col, Container, EmbeddedVideo, EmbeddedYouTube, PageBanner, Row, SocialButton, Stack, Text, Thumbnail } from '@zigurous/forge-react'; // prettier-ignore
import { graphql } from 'gatsby';
import React from 'react';
import { DetailsList, Page } from '../../components';
import { trackEvent } from '../../firebase/analytics';
import type { TutorialData } from '../../types';

interface TutorialProps {
  data: { tutorial: TutorialData };
  location: Location;
}

export default function Tutorial({ data, location }: TutorialProps) {
  const { tutorial } = data;
  const metadata = {
    url: `https://zigurous.com/tutorials/${tutorial.id}`,
    title: `Zigurous • ${tutorial.title}`,
    description: tutorial.description,
    image: tutorial.image.sharp.original.src,
  };
  return (
    <Page className="tutorial" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-center">
            <Col className="mb-xxl" size={12} md={4}>
              <Text
                as="div"
                className="ml-xxxs"
                marginBottom="xs"
                type="subtitle"
              >
                {tutorial.subtitle}
              </Text>
              <Text as="h1" marginBottom="lg" type="title-lg">
                {tutorial.title}
              </Text>
              {tutorial.badges && tutorial.badges.length > 0 && (
                <Stack align="center" className="mb-lg" spacing="sm" wrap>
                  {tutorial.badges.map(badge => (
                    <Badge key={badge}>{badge}</Badge>
                  ))}
                </Stack>
              )}
              {tutorial.videoURL && (
                <SocialButton
                  link="youtube"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Watch on YouTube',
                      item_id: tutorial.id,
                    });
                  }}
                  shape="pill"
                  url={tutorial.videoURL}
                  variant="outline"
                >
                  Watch on YouTube
                </SocialButton>
              )}
            </Col>
            <Col>
              <Text className="text-pretty" type="body-lg">
                {tutorial.description}
              </Text>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="my-xxxl" fluid>
        <Row>
          <Col size={12} lg={8}>
            {tutorial.videoEmbedId && (
              <EmbeddedYouTube
                className="mb-xxl"
                origin="https://zigurous.com"
                videoId={tutorial.videoEmbedId}
              />
            )}
            {tutorial.videoPlaylistId && (
              <EmbeddedVideo
                className="youtube mb-xxl"
                id="youtube-player"
                src={`https://www.youtube.com/embed/videoseries?list=${tutorial.videoPlaylistId}`}
                title="YouTube"
              />
            )}
            {tutorial.videoThumbnail &&
              !tutorial.videoEmbedId &&
              !tutorial.videoPlaylistId && (
                <Thumbnail
                  aria-label={tutorial.title}
                  as="span"
                  className="mb-xxl"
                  image={{
                    src: tutorial.videoThumbnail.sharp.original.src,
                    width: tutorial.videoThumbnail.sharp.original.width,
                    height: tutorial.videoThumbnail.sharp.original.height,
                  }}
                />
              )}
          </Col>
          <Col>
            <DetailsList
              className="mb-xxl"
              details={tutorial.details}
              onLinkClick={detail => {
                trackEvent('external_link', {
                  content_type: 'link',
                  event_category: 'engagement',
                  event_label: detail.value,
                  item_id: tutorial.id,
                });
              }}
            />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export const query = graphql`
  query ($id: String!) {
    tutorial: tutorialsJson(id: { eq: $id }) {
      badges
      description
      details {
        key
        url
        value
      }
      id: jsonId
      image {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      subtitle
      title
      videoEmbedId
      videoPlaylistId
      videoThumbnail {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      videoURL
    }
  }
`;
